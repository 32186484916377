<template>
  <b-container fluid="sm">
    <p />
    <b-card
      title="Synchronise"
      img-src="img/icons/apple-touch-icon-152x152.png"
      img-alt="Synchronise"
      img-top
      tag="article"
      style="max-width: 20rem"
      class="mb-2"
    >
      <div>
        <b-form-group
          id="input-group-username"
          label="Username:"
          label-for="input-username"
        >
          <b-form-input
            id="input-username"
            v-model="form.username"
            placeholder="Enter Username"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-password"
          label="Password:"
          label-for="input-password"
        >
          <b-form-input
            id="input-password"
            type="password"
            v-model="form.password"
            placeholder="Enter Password"
            required
          ></b-form-input>
        </b-form-group>

        <b-overlay
          :show="busy"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="onHidden"
        >
          <b-button
            ref="button"
            :disabled="busy"
            variant="primary"
            @click="onInitiate"
          >
            Initiate
          </b-button>
        </b-overlay>
      </div>
      <template #footer>
        <small class="text-muted"></small>

        <small class="text-muted" v-if="sync">
          Last synchronisation at {{ sync.synctime }}
        </small>

        <small v-if="!sync"> Never synchronised, synchronise now. </small>
      </template>
    </b-card>
  </b-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";

import { db } from "@/store/db";

export default {
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      token: null,
      tokenStr: null,
      agent: null,
      busy: false,
      timeout: null,
      sync: null,
    };
  },
  methods: {
    async loadStatus() {
      let data = await db.status.toArray();
      this.sync = data[0] || null;
    },
    async loadAgent() {
      let data = await db.agents.toArray();
      this.agent = data[0] || null;
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
    parseJwt(token) {
      var base64Payload = token.split(".")[1];
      var payload = Buffer.from(base64Payload, "base64");
      return JSON.parse(payload.toString());
    },
    async saveAgent(item) {
      let data = await db.agents
        .where("agent_id")
        .equals(parseInt(item.agent_id))
        .first();

      if (data !== null) {
        db.agents.put({
          ...item,
          date_synced: new Date().getTime(),
        });
      } else {
        db.agents.add({
          ...item,
          date_synced: new Date().getTime(),
        });
      }
    },
    async pullAgents() {
      axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get("/api/v1/agents?agent_status=ACTIVE&user_id=" + this.token.user_id)
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("Missing Data");
            return;
          } else {
            console.info("Syncing Agents....");
          }

          try {
            response.data.results.forEach((item) => {
              this.saveAgent(item);
              //load the agent
              this.agent = item;
              this.pullAreas();
            });

            console.info("Agents synced....");
          } catch (error) {
            console.info("saveAgent issue: " + error);
          }
        })
        .catch((e) => {
          console.info(
            "Agent syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    async saveTLUType(item) {
      let data = await db.tlutypes
        .where("tlu_type_id")
        .equals(parseInt(item.tlu_type_id))
        .first();

      if (data !== null) {
        db.tlutypes.put({
          ...item,
          date_synced: new Date().getTime(),
        });
      } else {
        db.tlutypes.add({
          ...item,
          date_synced: new Date().getTime(),
        });
      }
    },
    async pullTLUTypes() {
      axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get(
          "/api/v1/tlu_types?all=true&columns=tlu_type_id,tlu_type,tlu&organisation_id=" +
            this.token.organisation_id
        )
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("Missing Data");
            return;
          } else {
            console.info("Syncing TLU Types....");
          }

          try {
            response.data.results.forEach((item) => {
              this.saveTLUType(item);
            });

            console.info("TLU Types synced....");
          } catch (error) {
            console.info("saveTLUType issue: " + error);
          }
        })
        .catch((e) => {
          console.info(
            "TLUs syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    async saveArea(item) {
      let data = await db.areas
        .where("agent_area_id")
        .equals(parseInt(item.agent_area_id))
        .first();

      if (data !== null) {
        db.areas.put({
          ...item,
          date_synced: new Date().getTime(),
        });
      } else {
        db.areas.add({
          ...item,
          date_synced: new Date().getTime(),
        });
      }
    },
    async pullAreas() {
      if (this.agent == null) {
        await this.loadAgent();
      }

      if (this.agent != null) {
        axios
          .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${this.tokenStr}` },
          })
          .get("/api/v1/agent_areas?all=true&agent_id=" + this.agent.agent_id)
          .then((response) => {
            if (response.data == null || response.data.results == null) {
              console.info("Missing Data");
              return;
            } else {
              console.info("Syncing Areas....");
            }

            try {
              response.data.results.forEach((item) => {
                this.saveArea(item);
              });

              console.info("Areas synced....");
            } catch (error) {
              console.info("saveArea issue: " + error);
            }
          })
          .catch((e) => {
            console.info(
              "Areas syncs did not succeed. " + e.toString() + " failed"
            );
          });
      } else {
        console.info("Areas NOT synced, Agent NOT loaded.");
        setTimeout(this.pullAreas(), 1000);
      }
    },
    async saveSeason(item) {
      let data = await db.seasons
        .where("season_id")
        .equals(parseInt(item.season_id))
        .first();

      if (data !== null) {
        db.seasons.put({
          ...item,
          date_synced: new Date().getTime(),
        });
      } else {
        db.seasons.add({
          ...item,
          date_synced: new Date().getTime(),
        });
      }
      //get pricings
      this.pullPricings(item);
    },
    async pullSeasons() {
      axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get("/api/v1/seasons")
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("Missing Data");
            return;
          } else {
            console.info("Syncing Seasons....");
          }

          try {
            response.data.results.forEach((item) => {
              this.saveSeason(item);
            });

            console.info("Seasons synced....");
          } catch (error) {
            console.info("saveSeason issue: " + error);
          }
        })
        .catch((e) => {
          console.info(
            "Season syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    async savePricing(item) {
      let data = await db.pricing
        .where("pricing_tlu_type_id")
        .equals(parseInt(item.pricing_tlu_type_id))
        .first();

      if (data !== null) {
        db.pricing.put({
          ...item,
          date_synced: new Date().getTime(),
        });
      } else {
        db.pricing.add({
          ...item,
          date_synced: new Date().getTime(),
        });
      }
    },
    async pullPricings(season) {
      axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get(
          "/api/v1/data/vw_pricing_tlu_types?all=true&id=pricing_tlu_type_id" +
            "&iCols=pricing_tlu_type_id,pricing_id,tlu_type_id,tlu_type_premium,tlu_type_sum_insured,area_id,season_id" +
            "&sCols=season&columns=pricing_tlu_type_id,pricing_id,tlu_type_id,tlu_type_premium,tlu_type_sum_insured,area_id,season_id,season" +
            "&season_id=" +
            season.season_id
        )
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("Missing Data");
            return;
          } else {
            console.info("Syncing Pricings....");
          }

          try {
            response.data.results.forEach((item) => {
              this.savePricing(item);
            });

            console.info("Pricings synced....");
          } catch (error) {
            console.info("savePricing issue: " + error);
          }
        })
        .catch((e) => {
          console.info(
            "Pricing syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    /*Start Programs*/
    async saveOrganisation(item) {
      let data = await db.organisations
        .where("organisation_id")
        .equals(parseInt(item.organisation_id))
        .first();

      if (data !== null) {
        db.organisations.put({
          ...item,
          date_synced: new Date().getTime(),
        });
      } else {
        db.organisations.add({
          ...item,
          date_synced: new Date().getTime(),
        });
      }
    },
    async pullOrganisations() {
      axios
        .create({
          baseURL: window.authURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get(
          "/api/v1/organisations?all=true&columns=organisation_id,organisation_name"
        )
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("Missing Data");
            return;
          } else {
            console.info("Syncing Areas....");
          }

          try {
            response.data.results.forEach((item) => {
              this.saveOrganisation(item);
            });

            console.info("Organisation synced....");
          } catch (error) {
            console.info("saveOrganisation issue: " + error);
          }
        })
        .catch((e) => {
          console.info(
            "Organisation syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    async saveAgentProgram(item) {
      let data = await db.agent_programs
        .where("agent_program_id")
        .equals(parseInt(item.agent_program_id))
        .first();

      if (data !== null) {
        db.agent_programs.put({
          ...item,
          date_synced: new Date().getTime(),
        });
      } else {
        db.agent_programs.add({
          ...item,
          date_synced: new Date().getTime(),
        });
      }
    },
    async pullAgentPrograms() {
      if (this.agent == null) {
        await this.loadAgent();
      }

      if (this.agent != null) {
        axios
          .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${this.tokenStr}` },
          })
          .get(
            "/api/v1/agent_programs?all=true&agent_program_status=ACTIVE&agent_id=" +
              this.agent.agent_id
          )
          .then((response) => {
            if (response.data == null || response.data.results == null) {
              console.info("Missing Data");
              return;
            } else {
              console.info("Syncing Agent Programs ....");
            }

            try {
              response.data.results.forEach((item) => {
                this.saveAgentProgram(item);
                //pull the areas
                this.pullProgramAreas(item.program_id)
                //pull the financiers
                this.pullProgramFinanciers(item.program_id)
              });

              console.info("Agent Program synced....");
            } catch (error) {
              console.info("saveAgentProgram issue: " + error);
            }
          })
          .catch((e) => {
            console.info(
              "Agent Program syncs did not succeed. " + e.toString() + " failed"
            );
          });
      } else {
        console.info("Agent Programs NOT synced, Agent NOT loaded.");
        setTimeout(this.pullAgentPrograms(), 1000);
      }
    },
    async saveProgramArea(item) {
      let data = await db.program_areas
        .where("program_area_id")
        .equals(parseInt(item.program_area_id))
        .first();

      if (data !== null) {
        db.program_areas.put({
          ...item,
          date_synced: new Date().getTime(),
        });
      } else {
        db.program_areas.add({
          ...item,
          date_synced: new Date().getTime(),
        });
      }
    },
    async pullProgramAreas(program_id) {
      axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get(
          "/api/v1/program_areas?all=true&program_area_status=ACTIVE&program_id=" +
            program_id
        )
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("Missing Data");
            return;
          } else {
            console.info("Syncing Program Areas ....");
          }

          try {
            response.data.results.forEach((item) => {
              this.saveProgramArea(item);
            });

            console.info("Program Area synced....");
          } catch (error) {
            console.info("saveProgramArea issue: " + error);
          }
        })
        .catch((e) => {
          console.info(
            "Program Area syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    async saveProgramFinancier(item) {
      let data = await db.program_financiers
        .where("program_financier_id")
        .equals(parseInt(item.program_financier_id))
        .first();
      
      let financier = await db.organisations
        .where("organisation_id")
        .equals(parseInt(item.financier_id))
        .first();

      if (data !== null) {
        db.program_financiers.put({
          financier:financier.organisation_name,
          ...item,
          date_synced: new Date().getTime(),
        });
      } else {
        db.program_financiers.add({
          financier:financier.organisation_name,
          ...item,
          date_synced: new Date().getTime(),
        });
      }
    },
    async pullProgramFinanciers(program_id) {
      axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get(
          "/api/v1/program_financiers?all=true&program_financier_status=ACTIVE&program_id=" +
            program_id
        )
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("Missing Data");
            return;
          } else {
            console.info("Syncing Program Financiers ....");
          }

          try {
            response.data.results.forEach((item) => {
              this.saveProgramFinancier(item);
            });

            console.info("Program Financiers synced....");
          } catch (error) {
            console.info("saveProgramFinancier issue: " + error);
          }
        })
        .catch((e) => {
          console.info(
            "Program Financier syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    /*End Programs*/
    async saveHerder(item, resource) {
      let data = await db.herders.where("id").equals(parseInt(item.id)).first();

      if (data !== null && resource !== null && !(resource instanceof Array)) {
        let id = await db.herders.put({
          ...item,
          ...resource,
          date_synced: new Date().getTime(),
        });
      }
    },
    async syncHerder(item) {
      let resource = null;
      let that = this;

      axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get(`/api/v1/herders?herder_number=${item.herder_number}`)
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("New Herder, upload");

            //update fields
            item.herder_id = null;
            item.agent_id = this.agent.agent_id;
            item.organisation_id = parseInt(this.token.organisation_id);

            //save
            axios
              .create({
                baseURL: window.ibliURL,
                headers: { Authorization: `Bearer ${this.tokenStr}` },
              })
              .post("/api/v1/herders", item)
              .then((response) => {
                resource = response.data.resource;

                try {
                  that.saveHerder(item, resource);

                  console.info("Herder synced....");
                } catch (error) {
                  console.info("saveHerder issue: " + error);
                }

                console.info("Herder Saved");
              })
              .catch((e) => {
                console.info("Herder not Saved");

                var errors = e.response.data.errors;
                if (errors instanceof Array) {
                  errors.forEach((element) => {
                    console.error("Herder Upload Error: " + element.message);
                  });
                }
              });
          } else {
            console.info(
              "Existing Herder, push update if changed before, otherwise ignore...."
            );
            resource = response.data.results[0];

            if (
              resource.record_version <= item.record_version ||
              item.record_version == null
            ) {
              //update fields
              item.herder_id = resource.herder_id;
              item.agent_id = this.agent.agent_id;
              item.organisation_id = parseInt(this.token.organisation_id);
              item.record_version = resource.record_version;

              //save
              axios
                .create({
                  baseURL: window.ibliURL,
                  headers: { Authorization: `Bearer ${this.tokenStr}` },
                })
                .put("/api/v1/herders/" + resource.herder_id, item)
                .then((response) => {
                  resource = response.data.resource;

                  try {
                    that.saveHerder(item, resource);

                    console.info("Herder synced....");
                  } catch (error) {
                    console.info("saveHerder issue: " + error);
                  }

                  console.info("Herder Saved");
                })
                .catch((e) => {
                  console.info("Herder not Saved");

                  var errors = e.response.data.errors;
                  if (errors instanceof Array) {
                    errors.forEach((element) => {
                      console.error("Herder Upload Error: " + element.message);
                    });
                  }
                });
            }
          }
        })
        .catch((e) => {
          console.info(
            "Herder syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    async uploadHerders() {
      if (this.agent != null) {
        let data = await db.herders.toArray();

        data.forEach((item) => {
          console.info(`Herder: ${item.id}`);
          this.syncHerder(item);
        });
      }
    },
    async saveBeneficiary(item, resource) {
      let data = await db.beneficiaries
        .where("id")
        .equals(parseInt(item.id))
        .first();

      if (data !== null && resource !== null && !(resource instanceof Array)) {
        db.beneficiaries.put({
          ...item,
          ...resource,
          date_synced: new Date().getTime(),
        });
      }
    },
    async uploadBeneficiaries() {
      if (this.agent != null) {
        let data = await db.beneficiaries.toArray();

        data.forEach((item) => {
          this.syncBeneficiary(item);
        });
      }
    },
    async syncBeneficiary(item) {
      let resource = null;

      let that = this;

      let herder = await db.herders
        .where("id")
        .equals(parseInt(item.herder_x_id))
        .first();

      if (herder.herder_id !== null && item !== null) {
        axios
          .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${this.tokenStr}` },
          })
          .get(`/api/v1/beneficiaries?herder_id=${herder.herder_id}`)
          .then((response) => {
            if (response.data == null || response.data.results == null) {
              console.info("New Beneficiary, upload");

              //update fields
              item.beneficiary_id = null;
              item.organisation_id = parseInt(this.token.organisation_id);
              item.herder_id = parseInt(herder.herder_id);

              //save
              axios
                .create({
                  baseURL: window.ibliURL,
                  headers: { Authorization: `Bearer ${this.tokenStr}` },
                })
                .post("/api/v1/beneficiaries", item)
                .then((response) => {
                  resource = response.data.resource;

                  try {
                    that.saveBeneficiary(item, resource);

                    console.info("Beneficiary synced....");
                  } catch (error) {
                    console.info("saveBeneficiary issue: " + error);
                  }

                  console.info("Beneficiary Saved");
                })
                .catch((e) => {
                  console.info("Beneficiary not Saved");

                  var errors = e.response.data.errors;
                  if (errors instanceof Array) {
                    errors.forEach((element) => {
                      console.error(
                        "Beneficiary Upload Error: " +
                          element.message.toString()
                      );
                    });
                  }
                });
            } else {
              console.info(
                "Existing Beneficiary, push update if changed before, otherwise ignore...."
              );
              resource = response.data.results[0];

              if (
                resource.record_version <= item.record_version ||
                item.record_version == null
              ) {
                //update fields
                item.beneficiary_id = resource.beneficiary_id;
                item.herder_id = resource.herder_id;
                item.organisation_id = parseInt(this.token.organisation_id);
                item.record_version = resource.record_version;

                //save
                axios
                  .create({
                    baseURL: window.ibliURL,
                    headers: { Authorization: `Bearer ${this.tokenStr}` },
                  })
                  .put("/api/v1/beneficiaries/" + resource.beneficiary_id, item)
                  .then((response) => {
                    resource = response.data.resource;

                    try {
                      that.saveBeneficiary(item, resource);

                      console.info("Beneficiary synced....");
                    } catch (error) {
                      console.info("saveBeneficiary issue: " + error);
                    }

                    console.info("Beneficiary Saved");
                  })
                  .catch((e) => {
                    console.info("Beneficiary not Saved");

                    var errors = e.response.data.errors;
                    if (errors instanceof Array) {
                      errors.forEach((element) => {
                        console.error(
                          "Beneficiary Upload Error: " +
                            element.message.toString()
                        );
                      });
                    }
                  });
              }
            }
          })
          .catch((e) => {
            console.info(
              "Beneficiary syncs did not succeed. " + e.toString() + " failed"
            );
          });
      } else {
        console.info("Beneficiary not synced, do sync after herder synced");
        setTimeout(this.syncBeneficiary(item), 1000);
      }
    },
    async saveTLU(item, resource) {
      let data = await db.tlus.where("id").equals(parseInt(item.id)).first();

      if (data !== null && resource !== null && !(resource instanceof Array)) {
        await db.tlus.put({
          ...item,
          ...resource,
          date_synced: new Date().getTime(),
        });

        //upload TLU compositions
        await this.syncTLUCompositions(item);
      }
    },
    async syncTLUs() {
      let data = await db.tlus.toArray();

      data.forEach((item) => {
        console.info(
          `Sync Herder TLU: ${item.herder_x_id} Area: ${item.area_name}`
        );

        this.syncTLU(item);
      });
    },
    async syncTLU(item) {
      console.info(`Sync TLU: ${item.id}`);

      let that = this;

      let herder = await db.herders
        .where("id")
        .equals(parseInt(item.herder_x_id))
        .first();

      let resource = null;

      if (herder.herder_id !== null) {
        axios
          .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${this.tokenStr}` },
          })
          .get(
            `/api/v1/tlus?herder_id=${herder.herder_id}&area_id=${item.area_id}`
          )
          .then((response) => {
            if (response.data == null || response.data.results == null) {
              console.info("New TLU, upload");

              //update fields
              item.tlu_id = null;
              item.organisation_id = parseInt(this.token.organisation_id);
              item.herder_id = parseInt(herder.herder_id);

              //save
              axios
                .create({
                  baseURL: window.ibliURL,
                  headers: { Authorization: `Bearer ${this.tokenStr}` },
                })
                .post("/api/v1/tlus", item)
                .then((response) => {
                  resource = response.data.resource;

                  try {
                    that.saveTLU(item, resource);

                    console.info("TLU synced....");
                  } catch (error) {
                    console.info("saveTLU issue: " + error);
                  }

                  console.info("TLU Saved");
                })
                .catch((e) => {
                  console.info("TLU not Saved");

                  var errors = e.response.data.errors;
                  if (errors instanceof Array) {
                    errors.forEach((element) => {
                      console.error(
                        "TLU Upload Error: " + element.message.toString()
                      );
                    });
                  }
                });
            } else {
              console.info(
                "Existing TLU, push update if changed before, otherwise ignore...."
              );
              resource = response.data.results[0];

              if (
                resource.record_version <= item.record_version ||
                item.record_version == null
              ) {
                //update fields
                item.tlu_id = resource.tlu_id;
                item.herder_id = resource.herder_id;
                item.organisation_id = parseInt(this.token.organisation_id);
                item.record_version = resource.record_version;

                //save
                axios
                  .create({
                    baseURL: window.ibliURL,
                    headers: { Authorization: `Bearer ${this.tokenStr}` },
                  })
                  .put("/api/v1/tlus/" + resource.tlu_id, item)
                  .then((response) => {
                    resource = response.data.resource;

                    try {
                      that.saveTLU(item, resource);

                      console.info("TLU synced....");
                    } catch (error) {
                      console.info("saveTLU issue: " + error);
                    }

                    console.info("TLU Saved");
                  })
                  .catch((e) => {
                    console.info("TLU not Saved");

                    var errors = e.response.data.errors;
                    if (errors instanceof Array) {
                      errors.forEach((element) => {
                        console.error(
                          "TLU Upload Error: " + element.message.toString()
                        );
                      });
                    }
                  });
              }
            }
          })
          .catch((e) => {
            console.info(
              "TLU syncs did not succeed. " + e.toString() + " failed"
            );
          });
      } else {
        console.info("TLU not synced, do sync after herder synced");
        setTimeout(this.syncTLU(item), 1000);
      }
    },
    async saveTLUComposition(item, resource) {
      let data = await db.tlucompositions
        .where("id")
        .equals(parseInt(item.id))
        .first();

      if (data !== null && resource !== null && !(resource instanceof Array)) {
        db.tlucompositions.put({
          ...item,
          ...resource,
          date_synced: new Date().getTime(),
        });
      }
    },
    async syncTLUComposition(item) {
      let resource = null;
      let that = this;

      let tlu = await db.tlus
        .where("id")
        .equals(parseInt(item.tlu_x_id))
        .first();

      if (tlu !== null && tlu.tlu_id !== null) {
        axios
          .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${this.tokenStr}` },
          })
          .get(
            `/api/v1/tlu_compositions?tlu_id=${tlu.tlu_id}&tlu_type_id=${item.tlu_type_id}`
          )
          .then((response) => {
            if (response.data == null || response.data.results == null) {
              console.info("New TLU Composition, upload");

              //update fields
              item.tlu_composition_id = null;
              item.organisation_id = parseInt(this.token.organisation_id);
              item.herder_id = parseInt(tlu.herder_id);
              item.tlu_id = parseInt(tlu.tlu_id);
              item.record_version = null;

              //save
              axios
                .create({
                  baseURL: window.ibliURL,
                  headers: { Authorization: `Bearer ${this.tokenStr}` },
                })
                .post("/api/v1/tlu_compositions", item)
                .then((response) => {
                  resource = response.data.resource;

                  try {
                    that.saveTLUComposition(item, resource);

                    console.info("TLU Composition synced....");
                  } catch (error) {
                    console.info("saveTLU issue: " + error);
                  }

                  console.info("TLU Composition Saved");
                })
                .catch((e) => {
                  console.info("TLU Composition not Saved");

                  var errors = e.response.data.errors;
                  if (errors instanceof Array) {
                    errors.forEach((element) => {
                      console.error(
                        "TLU Composition Upload Error: " +
                          element.message.toString()
                      );
                    });
                  }
                });
            } else {
              console.info(
                "Existing TLU Composition, push update if changed before, otherwise ignore...."
              );
              resource = response.data.results[0];

              if (
                resource.record_version <= item.record_version ||
                item.record_version == null
              ) {
                //update fields
                item.tlu_composition_id = resource.tlu_composition_id;
                item.herder_id = resource.herder_id;
                item.organisation_id = parseInt(this.token.organisation_id);
                item.tlu_id = resource.tlu_id;
                item.record_version = resource.record_version;

                //save
                axios
                  .create({
                    baseURL: window.ibliURL,
                    headers: { Authorization: `Bearer ${this.tokenStr}` },
                  })
                  .put(
                    "/api/v1/tlu_compositions/" + resource.tlu_composition_id,
                    item
                  )
                  .then((response) => {
                    resource = response.data.resource;

                    try {
                      that.saveTLUComposition(item, resource);

                      console.info("TLU Composition synced....");
                    } catch (error) {
                      console.info("saveTLU issue: " + error);
                    }

                    console.info("TLU Composition Saved");
                  })
                  .catch((e) => {
                    console.info("TLU Composition not Saved");

                    var errors = e.response.data.errors;
                    if (errors instanceof Array) {
                      errors.forEach((element) => {
                        console.error(
                          "TLU Composition Upload Error: " +
                            element.message.toString()
                        );
                      });
                    }
                  });
              }
            }
          })
          .catch((e) => {
            console.info(
              "TLU Composition syncs did not succeed. " +
                e.toString() +
                " failed"
            );
          });
      } else {
        console.info("TLU Composition not synced, do sync after herder synced");
        setTimeout(this.syncTLUComposition(item), 1000);
      }
    },
    async syncTLUCompositions(tlu) {
      let data = await db.tlucompositions
        .where("tlu_x_id")
        .equals(parseInt(tlu.id))
        .toArray();

      data.forEach((item) => {
        this.syncTLUComposition(item);
      });
    },
    async saveGroup(item, resource) {
      let data = await db.groups.where("id").equals(parseInt(item.id)).first();

      if (data !== null && resource !== null && !(resource instanceof Array)) {
        db.groups.put({
          ...item,
          ...resource,
          date_synced: new Date().getTime(),
        });
      }
    },
    async syncGroup(item) {
      let resource = null;
      let that = this;

      axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get(
          `/api/v1/groups?group_number=${item.group_number}&organisation_id=${this.token.organisation_id}`
        )
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("New Group, upload");

            //update fields
            item.group_id = null;
            item.agent_id = this.agent.agent_id;
            item.organisation_id = parseInt(this.token.organisation_id);

            //save
            axios
              .create({
                baseURL: window.ibliURL,
                headers: { Authorization: `Bearer ${this.tokenStr}` },
              })
              .post("/api/v1/groups", item)
              .then((response) => {
                resource = response.data.resource;

                try {
                  that.saveGroup(item, resource);

                  console.info("Group synced....");
                } catch (error) {
                  console.info("saveGroup issue: " + error);
                }

                console.info("Group Saved");
              })
              .catch((e) => {
                console.info("Group not Saved");

                var errors = e.response.data.errors;
                if (errors instanceof Array) {
                  errors.forEach((element) => {
                    console.error("Group Upload Error: " + element.message);
                  });
                }
              });
          } else {
            console.info(
              "Existing Group, push update if changed before, otherwise ignore...."
            );
            resource = response.data.results[0];

            if (resource.record_version <= item.record_version) {
              //save
              axios
                .create({
                  baseURL: window.ibliURL,
                  headers: { Authorization: `Bearer ${this.tokenStr}` },
                })
                .put("/api/v1/groups/" + resource.group_id, item)
                .then((response) => {
                  resource = response.data.resource;

                  try {
                    that.saveGroup(item, resource);

                    console.info("Group synced....");
                  } catch (error) {
                    console.info("saveGroup issue: " + error);
                  }

                  console.info("Group Saved");
                })
                .catch((e) => {
                  console.info("Group not Saved");

                  var errors = e.response.data.errors;
                  if (errors instanceof Array) {
                    errors.forEach((element) => {
                      console.error("Group Upload Error: " + element.message);
                    });
                  }
                });
            }
          }
        })
        .catch((e) => {
          console.info(
            "Group syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    async uploadGroups() {
      if (this.agent != null) {
        let data = await db.groups.toArray();

        data.forEach((item) => {
          this.syncGroup(item);
        });
      }
    },
    async saveHerderGroup(item, resource) {
      let data = await db.groupherders
        .where("id")
        .equals(parseInt(item.id))
        .first();

      if (data !== null && resource !== null && !(resource instanceof Array)) {
        db.groupherders.put({
          ...item,
          ...resource,
          date_synced: new Date().getTime(),
        });
      }
    },
    async syncHerderGroup(item) {
      let resource = null;
      let that = this;

      let herder = await db.herders
        .where("id")
        .equals(parseInt(item.herder_x_id))
        .first();
      let group = await db.groups
        .where("id")
        .equals(parseInt(item.group_x_id))
        .first();

      axios
        .create({
          baseURL: window.ibliURL,
          headers: { Authorization: `Bearer ${this.tokenStr}` },
        })
        .get(
          `/api/v1/group_herders?group_id=${group.group_id}&herder=${herder.herder_id}`
        )
        .then((response) => {
          if (response.data == null || response.data.results == null) {
            console.info("New Herder Group, upload");

            //update fields
            item = {
              id: item.id,
              herder_id: herder.herder_id,
              group_id: group.group_id,
              group_herder_status: "ACTIVE",
              organisation_id: parseInt(this.token.organisation_id),
            };

            //save
            axios
              .create({
                baseURL: window.ibliURL,
                headers: { Authorization: `Bearer ${this.tokenStr}` },
              })
              .post("/api/v1/group_herders", item)
              .then((response) => {
                resource = response.data.resource;

                try {
                  that.saveHerderGroup(item, resource);

                  console.info("Herder Group synced....");
                } catch (error) {
                  console.info("saveHerderGroup issue: " + error);
                }

                console.info("Herder Group Saved");
              })
              .catch((e) => {
                console.info("Herder Group not Saved");

                var errors = e.response.data.errors;
                if (errors instanceof Array) {
                  errors.forEach((element) => {
                    console.error(
                      "Herder Group Upload Error: " + element.message
                    );
                  });
                }
              });
          } else {
            console.info(
              "Existing Herder Group, push update if changed before, otherwise ignore...."
            );
            resource = response.data.results[0];

            if (resource.record_version <= item.record_version) {
              //
              item.group_herder_status = "ACTIVE";

              //save
              axios
                .create({
                  baseURL: window.ibliURL,
                  headers: { Authorization: `Bearer ${this.tokenStr}` },
                })
                .put("/api/v1/group_herders/" + resource.group_herder_id, item)
                .then((response) => {
                  resource = response.data.resource;

                  try {
                    that.saveHerderGroup(item, resource);

                    console.info("Herder Group synced....");
                  } catch (error) {
                    console.info("saveHerderGroup issue: " + error);
                  }

                  console.info("Herder Group Saved");
                })
                .catch((e) => {
                  console.info("Herder Group not Saved");

                  var errors = e.response.data.errors;
                  if (errors instanceof Array) {
                    errors.forEach((element) => {
                      console.error(
                        "Herder Group Upload Error: " + element.message
                      );
                    });
                  }
                });
            }
          }
        })
        .catch((e) => {
          console.info(
            "Herder Group syncs did not succeed. " + e.toString() + " failed"
          );
        });
    },
    async uploadHerderGroups() {
      let data = await db.groupherders.toArray();

      data.forEach((item) => {
        this.syncHerderGroup(item);
      });
    },
    async saveQuotation(item, resource) {
      let data = await db.quotations
        .where("id")
        .equals(parseInt(item.id))
        .first();

      if (data !== null && resource !== null && !(resource instanceof Array)) {
        db.quotations.put({
          ...item,
          ...resource,
          date_synced: new Date().getTime(),
        });
      }
    },
    async syncQuotation(item) {
      let resource = null;

      let that = this;

      let herder = await db.herders
        .where("id")
        .equals(parseInt(item.herder_x_id))
        .first()
        .catch(() => {
          return { herder_id: null };
        });

      let group = await db.groups
        .where("id")
        .equals(parseInt(item.group_x_id))
        .first()
        .catch(() => {
          return { group_id: null };
        });

      if (
        (herder != null || group != null) &&
        (herder.herder_id != null || group.group_id != null)
      ) {
        axios
          .create({
            baseURL: window.ibliURL,
            headers: { Authorization: `Bearer ${this.tokenStr}` },
          })
          .get(
            `/api/v1/quotations?group_id=${group.group_id}&herder_id=${herder.herder_id}&area_id=${item.area_id}&season_id=${item.season_id}`
          )
          .then((response) => {
            if (response.data == null || response.data.results == null) {
              console.info("New Quotation, upload");

              //update fields
              item.quotation_id = null;
              item.herder_id = herder.herder_id;
              item.group_id = group.group_id;
              item.organisation_id = parseInt(this.token.organisation_id);

              //save
              axios
                .create({
                  baseURL: window.ibliURL,
                  headers: { Authorization: `Bearer ${this.tokenStr}` },
                })
                .post("/api/v1/quotations", item)
                .then((response) => {
                  resource = response.data.resource;

                  try {
                    that.saveQuotation(item, resource);

                    console.info("Quotation synced....");
                  } catch (error) {
                    console.info("saveQuotation issue: " + error);
                  }

                  console.info("Quotation Saved");
                })
                .catch((e) => {
                  console.info("Quotation not Saved");

                  var errors = e.response.data.errors;
                  if (errors instanceof Array) {
                    errors.forEach((element) => {
                      console.error(
                        "Quotation Upload Error: " + element.message
                      );
                    });
                  }
                });
            } else {
              console.info(
                "Existing Quotation, push update if changed before, otherwise ignore...."
              );
              resource = response.data.results[0];

              if (resource.record_version <= item.record_version) {
                //save
                axios
                  .create({
                    baseURL: window.ibliURL,
                    headers: { Authorization: `Bearer ${this.tokenStr}` },
                  })
                  .put("/api/v1/quotations/" + resource.quotation_id, item)
                  .then((response) => {
                    resource = response.data.resource;

                    try {
                      that.saveQuotation(item, resource);

                      console.info("Quotation synced....");
                    } catch (error) {
                      console.info("saveQuotation issue: " + error);
                    }

                    console.info("Quotation Saved");
                  })
                  .catch((e) => {
                    console.info("Quotation not Saved");

                    var errors = e.response.data.errors;
                    if (errors instanceof Array) {
                      errors.forEach((element) => {
                        console.error(
                          "Quotation Upload Error: " + element.message
                        );
                      });
                    }
                  });
              }
            }
          })
          .catch((e) => {
            console.info(
              "Quotation syncs did not succeed. " + e.toString() + " failed"
            );
          });
      } else {
        console.info("Quotation not synced, do sync after herder|group synced");
        setTimeout(this.syncQuotation(item), 1000);
      }
    },
    async uploadQuotations() {
      let data = await db.quotations.toArray();

      data.forEach((item) => {
        this.syncQuotation(item);
      });
    },
    async doSync() {
      //get tlu types
      await this.pullTLUTypes();

      //get agents
      await this.pullAgents();

      //get areas
      await this.pullAreas();

      //get seasons
      await this.pullSeasons();

      //get organisations
      await this.pullOrganisations();

      //get agent programs
      await this.pullAgentPrograms();

      //upload herders
      await this.uploadHerders();

      //upload beneficiaries
      await this.uploadBeneficiaries();

      //upload groups
      await this.uploadGroups();

      //upload herder groups
      await this.uploadHerderGroups();

      //upload TLUs
      await this.syncTLUs();

      //upload quotations
      await this.uploadQuotations();

      //update
      if (this.sync) {
        db.status.put({
          id: this.sync.id,
          synctime: new Date(),
          status: "COMPLETED",
        });
      } else {
        db.status.add({
          synctime: new Date(),
          status: "COMPLETED",
        });
      }

      this.$bvToast.toast("Synchronisation completed", {
        target: "b-toaster-top-center",
        duration: 5000,
        position: "top-center",
        title: "Synchronisation",
        variant: "success",
      });

      this.loadStatus();

      this.busy = false;
    },
    async onInitiate() {
      this.busy = true;

      const params = new URLSearchParams();
      params.append("consumer_key", this.form.username);
      params.append("consumer_secret", this.form.password);

      //try access a resource
      axios
        .create({
          baseURL: window.authURL,
        })
        .post("/oauth/token", params)
        .then((response) => {
          this.response = response.data;

          this.tokenStr = this.response.token;
          this.token = this.parseJwt(this.tokenStr);

          this.doSync();
        })
        .catch(() => {
          this.busy = false;

          this.$bvToast.toast(
            "Login Failed. Please check your credentials and try again",
            {
              duration: 5000,
              position: "top-center",
              title: "Synchronisation",
              variant: "error",
            }
          );
        });
    },
  },
  async created() {
    await this.loadStatus();
    await this.loadAgent();
  },
};
</script>
<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand href="#">IBLI</b-navbar-brand>

      <b-navbar-nav right>
        <b-nav-item onclick="window.history.back()">&lt;</b-nav-item>
      </b-navbar-nav>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- <b-navbar-nav>
          <b-nav-item href="#/">Dashboard</b-nav-item>
        </b-navbar-nav> -->

        <b-navbar-nav>
          <b-nav-item href="#/sync">Sync</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav>
          <b-nav-item href="#/quote">Calculate Quote</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav>
          <b-nav-item href="#/sell">Sell IBLI</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav>
          <b-nav-item href="#/herders">Herders</b-nav-item>
        </b-navbar-nav>

        <!-- <b-navbar-nav>
          <b-nav-item href="#/groups">Groups</b-nav-item>
        </b-navbar-nav> -->

        <b-navbar-nav>
          <b-nav-item href="#/tlutypes">TLU Types</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav>
          <b-nav-item href="#/policies">Policies</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-button v-b-modal.modal-update v-show="updateExists"
      >Update Application</b-button
    >

    <b-modal id="modal-update" title="Update">
      <b-button v-on:click="refreshApp">Refresh Application</b-button>
    </b-modal>

    <router-view />
    <footer class="row justify-content-center">
      &copy; Nomad Pro Africa Version: 0.1.25
    </footer>
  </div>
</template>

<style scoped>
.bg-primary {
  background-color: #c39035 !important;
}
</style>

<script>
import update from "@/mixins/update";

export default {
  name: "Layout",
  mixins: [update],
};
</script>
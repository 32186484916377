<template>
  <div>
    <b-card title="Sell" tag="article">
      <b-form @submit="onCalculate" @reset="onReset">
        <!-- <b-form-group label="Client" v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="form.client"
            :aria-describedby="ariaDescribedby"
            name="client"
            value="herder"
            >Herder</b-form-radio
          >
          <b-form-radio
            v-model="form.client"
            :aria-describedby="ariaDescribedby"
            name="client"
            value="group"
            >Group</b-form-radio
          >
        </b-form-group> -->

        <b-form-row>
          <b-col v-if="form.client == 'herder'">
            <b-form-group
              id="input-group-herder_x_id"
              label="Herder:"
              label-for="input-herder_x_id"
            >
              <b-form-select
                id="input-herder_x_id"
                class="border-input"
                v-model="form.herder_x_id"
                :options="herders"
                v-on:change="loadAreas()"
                required
                placeholder="Select Herder"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col v-if="form.client == 'group'">
            <b-form-group
              id="input-group-group_x_id"
              label="Group:"
              label-for="input-group_x_id"
            >
              <b-form-select
                id="input-group_x_id"
                class="border-input"
                v-model="form.group_x_id"
                :options="groups"
                v-on:change="form.herder_x_id = null"
                required
                placeholder="Select Group"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-area"
              label="Area:"
              label-for="input-area_id"
            >
              <b-form-select
                id="input-area_id"
                class="border-input"
                v-model="form.area_id"
                v-on:change="loadPrograms()"
                :options="areas"
                required
                placeholder="Enter Area ID"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col v-if="premium > 0">
            <b-form-group
              id="input-group-program_id"
              label="Program:"
              label-for="input-program_id"
            >
              <b-form-select
                id="input-program_id"
                class="border-input"
                v-model="form.program_id"
                v-on:change="calculateFinancing"
                :options="programs"
                placeholder="Select Program"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-group
              id="input-group-season"
              label="Season:"
              label-for="input-season"
            >
              <b-form-select
                id="input-season"
                class="border-input"
                v-model="form.season_id"
                :options="seasons"
                required
                placeholder="Enter Season"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col></b-col>
        </b-form-row>

        <b-button type="submit" variant="primary">Calculate</b-button> &nbsp;
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
      <b-card class="mt-3" header="Breakdown" v-if="premium > 0">
        <b-table-simple hover small caption-top responsive>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="text-left">Description</b-th>
              <b-th class="text-left">Units</b-th>
              <b-th class="text-right">Amount</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in quote" :key="item.id">
              <b-th class="text-left">{{ item.tlu_type }}</b-th>
              <b-th class="text-right">{{
                item.quantity.toLocaleString()
              }}</b-th>
              <b-td class="text-right">{{
                item.premium.toLocaleString()
              }}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td colspan="3" variant="secondary" class="text-right">
                Total Premium:
                <b>{{ premium.toLocaleString() }}</b>
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
        <b-table-simple hover small caption-top responsive>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="text-left" colspan="2">Financier</b-th>
              <b-th class="text-right">Amount</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in financing" :key="item.id">
              <b-th class="text-left" colspan="2">{{ item.financier }}</b-th>
              <b-td class="text-right">{{
                item.contribution.toLocaleString()
              }}</b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-td colspan="3" variant="secondary" class="text-right">
                Total contribution:
                <b>{{ contribution.toLocaleString() }}</b>
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
        <b-table-simple>
          <b-tfoot>
            <b-tr>
              <b-td colspan="3" variant="secondary" class="text-right">
                To Pay:
                <b>{{ (premium - contribution).toLocaleString() }}</b>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="3" v-if="premium > 0">
                <b-form-group
                  id="input-group-notes"
                  label="Payment Reference:"
                  label-for="input-notes"
                >
                  <b-form-input
                    id="input-notes"
                    class="border-input"
                    type="text"
                    v-model="form.notes"
                    required
                    placeholder="Payment Reference"
                  ></b-form-input>
                </b-form-group>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="3" v-if="premium > 0">
                <b-button v-on:click="onSell" variant="primary">Sell</b-button>
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { db } from "@/store/db";

export default {
  data() {
    return {
      form: {
        client: "herder",
        herder_x_id: null,
        group_x_id: null,
        area_id: null,
        season_id: null,
        payment_terms: "SHARED",
      },
      paymentTerms: [
        { value: null, text: "-select one-" },
        { value: "FARMER", text: "FARMER" },
        { value: "FINANCING_PARTNER", text: "FINANCING PARTNER" },
        { value: "SHARED", text: "SHARED" },
      ],
      seasons: [],
      areas: [],
      tlutypes: [],
      tlutypeOptions: [],
      quote: [],
      financing: [],
      herders: [],
      groups: [],
      tlus: [],
      programs: [],
    };
  },
  computed: {
    premium: function () {
      return this.quote.reduce((prev, cur) => {
        return prev + cur.premium;
      }, 0);
    },
    contribution: function () {
      return this.financing.reduce((prev, cur) => {
        return prev + cur.contribution;
      }, 0);
    },
    sum_insured: function () {
      return this.quote.reduce((prev, cur) => {
        return prev + cur.sum_insured;
      }, 0);
    },
    quantity: function () {
      return this.quote.reduce((prev, cur) => {
        return prev + cur.quantity;
      }, 0);
    },
  },
  methods: {
    async calculatePricePerTLUType(element) {
      try {
        let price = await db.pricing
          .where(["tlu_type_id", "area_id", "season_id"])
          .equals([
            parseInt(element.tlu_type_id),
            parseInt(this.form.area_id),
            parseInt(this.form.season_id),
          ])
          .first();

        let tlutype = this.tlutypes.filter((item) => {
          return item.tlu_type_id == element.tlu_type_id;
        })[0];

        this.quote.push({
          id: element.tlu_type_id,
          tlu: tlutype.tlu,
          tlu_type: tlutype.tlu_type,
          quantity: parseInt(element.quantity),
          sum_insured: price.tlu_type_sum_insured * parseInt(element.quantity),
          premium: price.tlu_type_premium * parseInt(element.quantity),
        });
      } catch (e) {
        console.info(e);
      }
    },
    async calculatePricePerTLU(tlu) {
      let compositions = await db.tlucompositions
        .where("tlu_x_id")
        .equals(tlu.id)
        .toArray();

      if (compositions != null) {
        compositions.forEach((element) =>
          this.calculatePricePerTLUType(element)
        );
      }
    },
    async calculate() {
      this.tlus.forEach((element) => this.calculatePricePerTLU(element));
    },
    async calculateFinancing() {
      let entries = await db.program_financiers
        .where("program_id")
        .equals(parseInt(this.form.program_id))
        .toArray();

      this.financing = [];
      console.log("calculateFinancing:" + this.premium);

      entries.forEach((element) => {
        console.log({
          id: element.program_financier_id,
          financier: element.financier,
          contribution_type: element.contribution_type,
          contribution:
            element.contribution_type == "PERCENTAGE"
              ? (element.contribution * this.premium) / 100
              : element.contribution,
        });
        this.financing.push({
          id: element.program_financier_id,
          financier: element.financier,
          contribution_type: element.contribution_type,
          contribution:
            element.contribution_type == "PERCENTAGE"
              ? (element.contribution * this.premium) / 100
              : element.contribution,
        });
      });
    },
    async onCalculate(event) {
      event.preventDefault();

      this.quote = [];

      await this.loadTLUs();

      await this.calculate();
    },
    async onSell(event) {
      event.preventDefault();

      let toast = this.$bvToast;

      let existing = await db.quotations
        .filter((entry) => {
          return (
            (entry.herder_x_id == this.form.herder_x_id ||
              this.form.herder_x_id == null) &&
            (entry.group_x_id == this.form.group_x_id ||
              this.form.group_x_id == null) &&
            entry.area_id == this.form.area_id &&
            entry.season_id == this.form.season_id
          );
        })
        .first();

      if (existing === undefined) {
        let herder =
          this.form.herder_x_id !== null
            ? await db.herders
                .where("id")
                .equals(parseInt(this.form.herder_x_id))
                .first()
            : { first_name: "", second_name: "" };

        let group =
          this.form.group_x_id !== null
            ? await db.groups
                .where("id")
                .equals(parseInt(this.form.group_x_id))
                .first()
            : { group_number: "", group_name: "" };

        let season = await db.seasons
          .where("season_id")
          .equals(parseInt(this.form.season_id))
          .first();

        let area = await db.areas
          .where("area_id")
          .equals(parseInt(this.form.area_id))
          .first();

        console.info({
          ...this.form,
          herder: `${herder.first_name} ${herder.second_name}`,
          group: `${group.group_number} ${group.group_name}`,
          season: `${season.season}`,
          area: `${area.parent_area_name} ${area.area_name}`,
          quotation_date: new Date(),
          sum_insured: this.sum_insured,
          quotation_amount: this.premium,
          quantity: this.quantity,
        });

        db.quotations
          .add({
            ...this.form,
            herder: `${herder.first_name} ${herder.second_name}`,
            group: `${group.group_number} ${group.group_name}`,
            season: `${season.season}`,
            area: `${area.parent_area_name} ${area.area_name}`,
            quotation_date: new Date(),
            sum_insured: this.sum_insured,
            quotation_amount: this.premium,
            quantity: this.quantity,
          })
          .then(function () {
            toast.toast("Saved.", {
              duration: 5000,
              position: "top-center",
              title: "Sale",
              variant: "success",
            });
          })
          .catch(function () {
            toast.toast("Save Failed.", {
              duration: 5000,
              position: "top-center",
              title: "Sale",
              variant: "error",
            });
          });
      } else {
        toast.toast("Save Failed. A sale already exists.", {
          duration: 5000,
          position: "top-center",
          title: "Sale",
          variant: "error",
        });
      }
    },
    onReset(event) {
      event.preventDefault();
      // Trick to reset/clear native browser form validation state
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async loadSeasons() {
      let data = await db.seasons.toArray();

      this.seasons.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        this.seasons.push({
          value: element.season_id,
          text: element.season,
        });
      });
    },
    async loadAreas() {
      this.areas = [];

      let data = await db.tlus
        .where("herder_x_id")
        .equals(parseInt(this.form.herder_x_id))
        .toArray();

      this.areas.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        this.areas.push({
          value: element.area_id,
          text: element.area_name,
        });
      });
    },
    async loadPrograms() {
      this.programs = [];

      let data = await db.program_areas
        .where("area_id")
        .equals(parseInt(this.form.area_id))
        .toArray();

      this.programs.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        let program = db.agent_programs
          .where("program_id")
          .equals(parseInt(element.program_id))
          .first();

        let that = this;

        program.then(function (value) {
          that.programs.push({
            value: value.program_id,
            text: value.program_name,
          });
        });
      });
    },
    async loadTLUTypes() {
      this.tlutypes = await db.tlutypes.toArray();

      this.tlutypeOptions.push({ value: null, text: "-select one-" });

      if (this.tlutypes == null) return;

      this.tlutypes.forEach((element) => {
        this.tlutypeOptions.push({
          value: element.tlu_type_id,
          text: element.tlu_type,
        });
      });
    },
    async loadHerders() {
      let data = await db.herders.toArray();

      this.herders.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        this.herders.push({
          value: element.id,
          text:
            element.first_name +
            " " +
            element.second_name +
            ":" +
            element.phone_number,
        });
      });
    },
    async loadGroups() {
      let data = await db.groups.toArray();

      this.groups.push({ value: null, text: "-select one-" });

      if (data == null) return;

      data.forEach((element) => {
        this.groups.push({
          value: element.id,
          text: element.group_name + " " + element.group_number,
        });
      });
    },
    async loadTLUs() {
      let herder_x_ids = [];

      if (this.form.herder_x_id != undefined && this.form.herder_x_id != null) {
        herder_x_ids.push(parseInt(this.form.herder_x_id));
      } else if (
        this.form.group_x_id != undefined &&
        this.form.group_x_id != null
      ) {
        let groupherders = await db.groupherders
          .where("group_x_id")
          .equals(parseInt(this.form.group_x_id))
          .toArray();

        if (groupherders != null) {
          herder_x_ids = groupherders.map((element) => {
            return element.herder_x_id;
          });
        }
      }

      this.tlus = await db.tlus
        .where("herder_x_id")
        .anyOf(herder_x_ids)
        .filter((element) => {
          return element.area_id == this.form.area_id;
        })
        .toArray();
    },
  },
  created() {
    this.loadSeasons();
    this.loadTLUTypes();
    this.loadHerders();
    this.loadGroups();
  },
};
</script>